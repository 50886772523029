import { useEffect, useState } from "react";

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  keyframes,
} from "@chakra-ui/react";
import Layout from "../../components/Layout";

import {
  data1,
  data2,
  modeling1,
  modeling2,
  scoring1,
  scoring2,
  setup1,
  setup2,
} from "./assets";

import { supabase } from "../../services/supabase";
import { useUserStore } from "../../store/userStore";
import { Link } from "react-router-dom";

const trackerStates = [
  {
    name: "setup",
    title: "Setup",
    description: `Provide competitors you'd like to include in CONNECTORS beta and let's track your customers' Brand Love journey.`,
    image1: setup1,
    image2: setup2,
    to: "/connections",
  },
  {
    name: "data",
    title: "Data",
    description: `Connect data sources here. As each source is connected, we'll ensure that data is flowing into our modeling system smoothly.`,
    image1: data1,
    image2: data2,
    to: "/connections",
  },
  {
    name: "modeling",
    title: "Modeling",
    description: `Your data sources are all connected and flowing smoothly.<br/> Hang tight as we build your CONNECTORS beta score.`,
    image1: modeling1,
    image2: modeling2,
  },
  {
    name: "scoring",
    title: "Scoring",
    description: `The data modeling phase is complete, and your CONNECTORS beta score is ready! View results and analysis of your customers' journeys from brand awareness to brand loyalty.`,
    image1: scoring1,
    image2: scoring2,
  },
];

const animationKeyframes = keyframes`
0% { transform: scale(1); }
100% { transform: scale(1.1); }
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite alternate`;

export default function Tracker() {
  const { user } = useUserStore();
  const [currentTracker, setCurrentTracker] = useState<string>("setup");

  //   const currentTracker = "scoring"; //for testing

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    const run = async () => {
      const { data, error } = await supabase
        .from("pulse")
        .select()
        .eq("userId", user?.id)
        .single();

      setCurrentTracker(
        String(data?.tracker_status || "setup")
          .trim()
          .toLowerCase()
      );

      console.log({ data, error });
    };

    run();
  }, [user?.id]);

  return (
    <Layout>
      <Box px={8} py={8}>
        <Box mb={8} color="brand.700">
          <Heading>Connectors Tracker</Heading>
          <Text>
            <strong>Welcome to Connectors beta,</strong> where we use data modeling
            to illuminate your customer's journeys from brand awareness to brand
            loyalty.
          </Text>
        </Box>

        <Box mt={16}>
          <Grid
            templateColumns={{
              base: "repeat(1, minmax(120px, 1fr))",
              lg: "repeat(4, minmax(120px, 1fr))",
            }}
            gap={8}
          >
            {trackerStates.map((trackerState) => {
              const { image1, image2, title, description } = trackerState;

              return (
                <GridItem>
                  <Box maxWidth="180px" mx="auto">
                    {currentTracker === trackerState.name && (
                      <Link to={trackerState.to || "/tracker"}>
                        <Image src={image2} alt={title} animation={animation} />
                      </Link>
                    )}

                    {currentTracker !== trackerState.name && (
                      <Image src={image1} alt={title} opacity={0.6} />
                    )}
                  </Box>

                  {currentTracker === trackerState.name && (
                    <Box my={8}>
                      <Text
                        color="brand.700"
                        dangerouslySetInnerHTML={{ __html: description }}
                        // width="500px"
                        // overflowWrap="break-word"
                      />
                    </Box>
                  )}
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}
