import { useRef } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";

import { differenceInMinutes } from "date-fns";

import { supabase } from "../../services/supabase";

import Logo from "../../assets/logo.png";
import background from "../../assets/background.jpg";
import { useNavigate } from "react-router-dom";

export default function UpdatePasswordScreen() {
  const toast = useToast();
  const navigate = useNavigate();

  const pwdRef = useRef<HTMLInputElement>(null);
  const pwdRepeatRef = useRef<HTMLInputElement>(null);

  const handleUpdate = async () => {
    const pwd: string = pwdRef.current?.value ?? "";
    const pwdRepeat: string = pwdRepeatRef.current?.value ?? "";

    if (!pwd || pwd !== pwdRepeat) {
      toast({
        title: "Passwords don't match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    const { data, error } = await supabase.auth.updateUser({ password: pwd });

    console.log({ data, error });
    // return;

    if (error) {
      toast({
        title: error?.name,
        description: error?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    toast({
      title: "Updating Password",
      description: "Please wait",
      status: "info",
      duration: 3000,
      isClosable: true,
    });

    const user = data?.user;
    const confirmedAt = user?.confirmed_at
      ? new Date(user?.confirmed_at)
      : new Date();

    const isConfirmedRecently = differenceInMinutes(new Date(), confirmedAt) < 5;

    if (isConfirmedRecently && !user?.recovery_sent_at) {
      const pulseTable = await supabase
        .from("pulse")
        .select()
        .eq("userId", user?.id)
        .single();

      console.log({ pulseTable });
      // return;

      if (!pulseTable?.data?.id) {
        const { error } = await supabase.from("pulse").insert({
          userId: user?.id,
          first_name: "new user!",
          last_name: "new user!",
          company_name: user?.email ?? 'New Company',
        });
  
        console.log({ error });
      }

      /**
       * Send email
       */
      const mailto = data?.user?.email;
      const cc = pulseTable.data?.email_manager;

      await fetch(
        `https://theshipyard.com/pulse/welcome-email/?mailto=${mailto}&cc=${cc}`
      );
    }

    toast({
      title: "Password Updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    setTimeout(() => {
      navigate("/login");
    }, 3000);
  };

  return (
    <Stack
      minH={"100vh"}
      direction={{ base: "column", md: "row" }}
      backgroundImage={background}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdate();
          }}
          spacing={4}
          w={"full"}
          maxW={"md"}
          mt={12}
          color="brand.600"
        >
          <Image
            height={110}
            maxWidth="448px"
            my={{ base: "8px", md: "32px" }}
            objectFit="scale-down"
            pr={{ base: 1, md: 10 }}
            src={Logo}
          />

          <Heading fontSize={"2xl"} color="brand.700">
            Update your Password
          </Heading>

          <FormControl id="password">
            <FormLabel>New Password</FormLabel>
            <Input
              placeholder="Write your password"
              type="password"
              bg="white"
              ref={pwdRef}
              required={true}
            />
          </FormControl>

          <FormControl id="repeat">
            <FormLabel>Repeat Password</FormLabel>
            <Input
              placeholder="Repeat your password"
              type="password"
              bg="white"
              ref={pwdRepeatRef}
              required={true}
            />
          </FormControl>

          <Stack spacing={6}>
            <Button type="submit" colorScheme={"secondary"} variant={"solid"}>
              Update password
            </Button>
          </Stack>
        </Stack>
      </Flex>

      <Flex
        flex={1}
        alignItems="center"
        justifyContent="center"
        display={{ base: "none", md: "flex" }}
      />
    </Stack>
  );
}
