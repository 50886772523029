import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  // Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";

import { supabase } from "../../services/supabase";
import { useUserStore } from "../../store/userStore";

import Logo from "../../assets/logo.png";
import background from "../../assets/background.jpg";
import { Link, useNavigate } from "react-router-dom";

export default function LoginScreen() {
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState({ error: false, text: "" });

  const { user, setUser } = useUserStore();

  const rememberMeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleLogin = async () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password) {
      return setHelperText({ error: true, text: "Please fill in all fields" });
    }

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setHelperText({ error: true, text: error.message });
      return;
    } else {
      setHelperText({ error: false, text: "" });
    }

    const res = await supabase
      .from("pulse")
      .select()
      .eq("userId", data?.user?.id)
      .single();

    setUser({
      ...(data?.user || {}),
      info: res.data,
      rememberMe: rememberMeRef.current?.checked ?? false,
    });

    navigate("/connections");
  };

  useEffect(() => {
    const refreshAndRedirect = async () => {
      const { data, error } = await supabase.auth.refreshSession();

      console.log("remember: ", data, error);
      if (!error) {
        navigate("/connections");
      }
    };

    if (user?.rememberMe) {
      refreshAndRedirect();
    }
  }, [navigate, user?.rememberMe]);

  return (
    <Stack
      minH={"100vh"}
      direction={{ base: "column", md: "row" }}
      backgroundImage={background}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          spacing={4}
          w={"full"}
          maxW={"md"}
          mt={12}
        >
          <Image
            height={110}
            maxWidth="448px"
            my={{ base: "8px", md: "32px" }}
            objectFit="scale-down"
            pr={{ base: 1, md: 10 }}
            src={Logo}
          />

          <Heading fontSize={"2xl"} color="brand.500">
            Sign in to your account
          </Heading>

          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input type="email" bg="white" ref={emailRef} />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input type="password" bg="white" ref={passwordRef} />
          </FormControl>
          <Stack spacing={6}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align={"start"}
              justify={"space-between"}
            >
              {/* <Checkbox colorScheme="brand" ref={rememberMeRef}>
                Remember me
              </Checkbox> */}
              <Text
                color={"brand.500"}
                cursor="pointer"
                as={Link}
                to="/reset-password"
              >
                Forgot password?
              </Text>
            </Stack>

            {helperText.error && (
              <Box
                fontWeight="bold"
                textAlign="center"
                color={helperText.error ? "red.500" : "green.500"}
              >
                {helperText.text}
              </Box>
            )}

            <Button
              type="submit"
              colorScheme={"secondary"}
              variant={"solid"}
              // onClick={handleLogin}
            >
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Flex>

      <Flex
        flex={1}
        // background="mystic.500"
        alignItems="center"
        justifyContent="center"
        display={{ base: "none", md: "flex" }}
      >
        {/* <Image
          width="400px"
          height="466px"
          alt={"Login Image"}
          objectFit={"cover"}
          src={TSYLogo}
        /> */}
      </Flex>
    </Stack>
  );
}
