//services ids allowed to be created
export const allowedServices = [
    "eloqua",
    "facebook_ads",
    "facebook_pages",
    "google_ads",
    "google_analytics_4",
    "hubspot",
    "instagram_business",
    "linkedin_company_pages",
    // "linkedin_pages",
    "tiktok_ads",
    "twitter_ads",
    "twitter",
    "youtube_analytics",
  ];


export const getServiceName = (serviceName: string) => {
    return String(serviceName).replaceAll("_", " ");
  };