import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  BoxProps,
  FlexProps,
  Image,
} from "@chakra-ui/react";

import {
  FiHome,
  FiTrendingUp,
  FiLock,
  // FiCompass,
  FiMessageCircle,
} from "react-icons/fi";

import { IconType } from "react-icons";
import { Link } from "react-router-dom";

import Logo from "../../assets/logo.png";

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
  target?: string;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  to: string;
  target?: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: "Connector Tracker", icon: FiHome, to: "/tracker" },
  { name: "Connections", icon: FiTrendingUp, to: "/connections" },
  // { name: "FAQs", icon: FiCompass, to: "/faq" },
  { name: "Contact", icon: FiMessageCircle, to: "/contact" },
  {
    name: "Privacy Policy",
    icon: FiLock,
    to: "https://privacy.theshipyard.com",
    target: "_blank",
  },
  // {
  //   name: "Publishers",
  //   icon: FiLock,
  //   to: "/publisher",
  // },

  // { name: "Settings", icon: FiSettings, to: "/settings" },
];

const NavItem = ({ icon, to, children, target, ...rest }: NavItemProps) => {
  return (
    <Box
      _focus={{ boxShadow: "none" }}
      as={Link}
      color="brand.800"
      style={{ textDecoration: "none" }}
      target={target ?? "_self"}
      to={to}
    >
      <Flex
        align="center"
        cursor="pointer"
        p="4"
        role="group"
        _hover={{
          bg: "brand.500",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

export default function SidebarContent({ onClose, ...rest }: SidebarProps) {
  return (
    <Box
      transition="3s ease"
      bg="primary.500"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        px="6"
        justifyContent="space-between"
        bg="primary.400"
      >
        <Link to="/tracker">
          <Image
            alt="Logo"
            height={{ base: "64px", md: "56px" }}
            objectFit="cover"
            py="6px"
            src={Logo}
          />
        </Link>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          to={link.to}
          target={link.target}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
}
